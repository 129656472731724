<template>
    <div class="container-fluid">
        <div class="oml-newDesti-container mt-4">
            <div class="oml-newDesti-content">
                <div class="oml-newDesti-header">
                    <div class="topfilter pt-4">
                        <div class="col-12 my-auto title">
                            {{$t('Connect_your_destination')}}  (Mailjet)
                        </div>
                    </div>
                </div>

                <div class="oml-newDesti-searchFilter d-none">
                    <div class="oml-newDesti-search">
                        <div class="form-group input-searchZone-md mx-sm-2">
                            <i class="fa fa-search"></i>
                            <input type="text" class="form-control input-searchZone"  placeholder="Search your proffered lead destination" />
                        </div>
                    </div>
                    <div class="oml-newDesti-filter">
                        <label>Sort by :</label>
                        <div class="selectBox">
                            <multiselect v-model="value" :options="options" :searchable="false" :close-on-select="false" :show-labels="false" placeholder="Pick a value"></multiselect>
                        </div>
                        <div class="hr-y-e2e2e2"></div>
                        <div class="selectBox">
                            <multiselect v-model="value" :options="options" :searchable="false" :close-on-select="false" :show-labels="false" placeholder="Pick a value"></multiselect>
                        </div>
                    </div>
                </div>

                <div class="oml-newDesti-form mt-4" v-if="!saveForm">
                    <div class="oml-newDestiForm-title">
                        <div class="d-flex align-items-center justify-content-between">
                            <!-- <h5 class="mb-0">{{$t('Destination_via_Webhook')}}</h5> -->
                            <!-- <div class="custom-control custom-switch b-custom-control-lg d-flex align-items-center">
                                <b-form-checkbox name="check-button" switch v-model="active">
                                    {{$t('Active')}}
                                </b-form-checkbox>
                            </div>  -->
                        </div>

                    </div>

                    <div class="form-group pl-0 mt-4">
                        <label for="name"> {{$t('Name_of_destination')}} * </label>
                        <div class="input-withAction">
                            <input class="form-control"  v-model="destination.name" type="text" id="name" @change="changename" :placeholder="$t('Your_name_of_destination_here')"  />
                        </div>
                        <div class="input-withAction" v-if="isname"><p :style="{ color: 'red'}" class="fs-11 color-6c6c6 mt-1 mb-0">{{this.isname}}</p></div>
                    </div>

                    <div class="form-group pl-0 mt-4">
                        <label for="name"> {{$t('Request_URL')}} * </label>
                        <div class="input-withAction">
                            <input type="url" class="form-control" :placeholder="$t('Your_endPoint_of_destination_here')" v-model="destination.endpoint" disabled
                                   @change="changeendpoint"/>
                            <!-- <button @click="destination.endpoint = ''"><i class="ri-delete-bin-2-fill"></i></button> -->
                        </div>
                        <div v-if="isendpoint"><p :style="{ color: 'red'}" class="fs-11 color-6c6c6 mt-1 mb-0">{{this.isendpoint}}</p></div>
                    </div>
					
					<div class="form-group pl-0 mt-4">
                        <label for="name"> {{$t('Request_Method')}} * </label>
                        <div class="input-withAction">
							<multiselect 
								v-model="destination.method" 
								@change="changemethod"
								disabled
								:options="methods" 
								@input="changemethod" 
								:multiple="false" 
								:close-on-select="true" 
								:clear-on-select="true" 
								:preserve-search="true" 
								:placeholder="$t('Request_Method')"
							></multiselect>
                        </div>
                        <div v-if="ismethod"><p :style="{ color: 'red'}" class="fs-11 color-6c6c6 mt-1 mb-0">{{this.ismethod}}</p></div>
                    </div>

                    <div class="form-group pl-0 mt-4">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <label class="mb-0" for="name"> 
								{{$t('Request_headers')}}
								<a href="https://app.mailjet.com/account/api_keys" target="_blanc" class="fs-13 color-1e83ef fw-600">Get your access link here</a>
							</label>
                        </div>

                        <div class="groupInput-withAction mb-3" v-for="(header,j) in (destination.request_headers)" :key="j">
                            <div class="first-input">
                                <input v-model="header.key" type="text" class="form-control newAddhk" id="newAddhk" :placeholder="$t('Key')"/>
                            </div>
                            <i class="fa fa-long-arrow-right"></i>
                            <div class="second-input">
                                <input v-model="header.value" type="text" class="form-control" id="name" :placeholder="$t('Value')"  />
                            </div>
                        </div>

                    </div>

                   <div class="form-group pl-0 mt-4">
                        <label for="name"> {{$t('When_lead_status_changed_to')}} *</label>
                        <div class="input-withAction">
                            <multiselect v-model="idstatus" :options="liststatus" @input="changestatus" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" :placeholder="$t('Pick_statuses')" label="name" track-by="name" ></multiselect>
                        </div>
                        <div v-if="isstatus"><p :style="{ color: 'red'}" class="fs-11 color-6c6c6 mt-1 mb-0">{{this.isstatus}}</p></div>
                    </div>

                    <div class="form-group pl-0 mt-4">
                        <label for="name"> {{$t('Sources_where_to_apply')}} *</label>
                        <div class="input-withAction">
                            <multiselect v-model="idsources" :options="listsources" @input="changesource" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :placeholder="$t('Pick_sources')" label="name" track-by="name" ></multiselect>
                        </div>
                        <div v-if="issource"><p :style="{ color: 'red'}" class="fs-11 color-6c6c6 mt-1 mb-0">{{this.issource}}</p></div>
                    </div>

                    <div class="form-group pl-0 mt-4 d-none">
                        <label for="name"> {{$t('Request_parameters')}} </label>
                        <div class="groupInput-withAction">
                            <div class="first-input">
                                <input type="text" class="form-control" id="name" :placeholder="$t('Key')"  />
                            </div>
                            <i class="fa fa-long-arrow-right"></i>
                            <div class="second-input">
                                <input type="text" class="form-control" id="name" :placeholder="$t('Value')"  />
                            </div>
                            <button><i class="ri-add-line"></i></button>
                        </div>
                    </div>

                    <div v-if="!id_edit" class="oml-newDesti-actions">                        
                        <!-- <router-link to="/destination"> -->
                            <button class="save-btn" @click="saveWebhook">{{$t('Save')}}</button>
                        <!-- </router-link> -->
                    </div>
                    
                    <div v-if="id_edit" class="oml-newDesti-actions">
                        <!-- <router-link to="/destination"> -->
                            <button class="save-btn" @click="editWebhook">{{$t('Edit')}}</button>
                        <!-- </router-link> -->
                    </div>
                </div>

                
                <div class="oml-newDesti-savedData mt-4" v-if="!id_edit && saveForm">
                    <i class="ri-checkbox-circle-line"></i>
                    <h3>{{$t('Awsome')}}!</h3>
                    <p>{{$t('your_web_hook_destination_updated_successfully')}}!</p>
                    <button @click="eventCallBack"><i class="ri-add-line"></i>{{ $t('Create_new_destination') }}</button>
                </div>
                
                <router-link :to="{ path : '/destination/add'}" class="oml-newDesti-savedData mt-4" v-if="id_edit && saveForm">
                    <i class="ri-checkbox-circle-line"></i>
                    <h3>{{$t('Awsome')}}!</h3>
                    <p>{{$t('your_web_hook_destination_updated_successfully')}}!</p>
                    <button @click="eventCallBack"><i class="ri-add-line"></i>{{ $t('Create_new_destination') }}</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    import 'vue-multiselect/dist/vue-multiselect.min.css'


    export default {
        components: {
            Multiselect
        },
        data (){
            return {
                value: '',
                active: true,
                options: ['Select option', 'options', 'selected', 'mulitple', 'label', 'searchable', 'clearOnSelect', 'hideSelected', 'maxHeight', 'allowEmpty', 'showLabels', 'onChange', 'touched'],
                destination: {
                    name: '',
                    plateform: 'mailjet',
                    active: '',
                    endpoint: 'https://api.mailjet.com/v3/REST/contact',
                    method: 'Post',
                    settings:{"status_id":'',"sources_id":''},
                    request_headers: [{key:'',value:''}],
                    request_query: [],
                    parameters:[]
                },
                newHeader: {key:'',value:''},
                newQuery: {key:'',value:''},
                methods:['Post', 'Get'],
                // crms:['webhook', 'mailchimp','sendinblue'],
                crms:['webhook'],
                selected: "webhook",

                liststatus:[],
                listsources:[],
                idstatus:[],
                idsources: [],
                feedbackMessage: '',
                keyy: '',
                valuee: '',

                //data provisoir
                saveForm:false,

                multiSelectVal: null,
                multiSelectOpt: ['list', 'of', 'options'],

                url: '',
                isname: '',
                isendpoint: '',
                ismethod: '',
                isstatus: '',
                issource: '',
                isValid: false,
                regex:"^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$",

                destWebhook:true,
				mailshimpUrl:{
					part1:'',
					part2:'',
				}
            }
        },
        mounted(){
            this.getSourcesStatus();
        },
        created()
        {
            this.id_edit = this.$route.params.id
            if (this.id_edit) {
                this.getDataToEdit(this.id_edit)
            }
        },
        methods: {
            getParameters() {
				
				let that = this;
				that.loadingData 	 = true;
				
				let url   = '/webhook/getparameters';
				let method= 'GET';
                let dataa = {
                    // source_id : this.idsources, //sources
                    source_id : this.idsources.map(function(value) {
                        return value.id;
                    }), //sources
                 };
				let data  = {source_id : dataa.source_id}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {					
					that.loadingData 	 = false
                    that.destination.parameters = response.data.parameters;
                    // that.liststatus = response.data.statuses;
                    // that.listsources = response.data.sources;
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingData 	 = false
				})
				
			},

            getDataToEdit(id){
                let that = this;
				that.loadingData 	 = true;
				
				let url   = '/webhook/getDataToEdit';
				let method= 'GET';            
                let data = {
                     id : id //destination id
                };
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {					
					that.loadingData 	 = false		
                    that.destination  = response.data.settings;
                    that.idsources  = response.data.settings.idsources;
                    that.idstatus  = response.data.settings.idstatus;
                    that.active  = response.data.settings.active;
                    that.active  = response.data.settings.active;
					
					if(that.destination.plateform == 'mailchimp'){
						let urlArray = that.destination.endpoint.split("/")
						let urlPart1Array = urlArray[2].split(".")
						this.mailshimpUrl.part1 = urlPart1Array[0]
						this.mailshimpUrl.part2 = urlArray[5]
					}
					
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.loadingData 	 = false
				})
				
				
            },
            
            getSourcesStatus() {
				
				let that = this;
				that.loadingData 	 = true;
				
				let url   = '/webhook/getSourcesStatuses';
				let method= 'GET';            
				let data  = {}
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {					
					that.loadingData 	 = false					
                    that.liststatus  = response.data.statuses;
                    that.listsources = response.data.sources;

                    if (!this.id_edit) {
                        that.idsources  = response.data.sources;
                        // that.idstatus   = response.data.statuses;
                        this.getParameters();
                    }
	
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn');
					that.loadingData 	 = false;
				})
				
			},

            addRequestHeader() {
                console.log(this.destination)

                this.destination.request_headers.push({key:'',value:''})
            },
            addRequestQuery() {

                this.destination.request_query.push({key:'',value:''})

            },
            removeHeader: function (key) {
                this.destination.request_headers.splice(key, 1);
            },
            removeQuery: function (key) {
                this.destination.request_query.splice(key, 1);
            },
            saveWebhook: function () {
                if (this.destination.name == '') {
                    return this.isname = this.$t('The_name_is_required');
                }
                if (this.destination.endpoint == '') {
                    return this.isendpoint = this.$t('Enter_a_valid_url_endpoint_for_your_destination');
                }
                if (this.destination.method == '') {
                    return this.ismethod = this.$t('Choose_a_request_method');
                }                
                if (this.idsources == '') {
                    return this.issource = this.$t('Choose_at_least_1_source');
                }
                if (this.idstatus == '') {
                    return this.isstatus = this.$t('Choose_at_least_1_status');
                }

                let that = this;                
                let dataa = {
                     status_id : this.idstatus.map(function(value) {
                        return value.id;
                    }), //Statuses

                    // source_id : [this.idsources], //sources
                    source_id : this.idsources.map(function(value) {
                        return value.id;
                    }), //sources
                 };
                 that.destination.settings.status_id  = dataa.status_id;
                 that.destination.settings.sources_id = dataa.source_id;
                 that.destination.active = this.active;

                let url   = '/webhook/saveSettings';
				let method= 'POST';
				let data  = that.destination;

                 that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.loadingData = false;					
                    that.feedbackMessage = '';
                    if(!response.data.success) {
                        let error = response.data.errors
                        
                        if(error){
							Object.keys(error).forEach( function( item ) {
                                console.log(item)
								if(that.feedbackMessage != '') {
									that.feedbackMessage += '<br>'
								}
								that.feedbackMessage += item + "- " + error[item][0]
							} )
                            that.notifyMe(that.feedbackMessage, 'warn');
						}
					}else{
                        that.$router.push({path: '/destination'});
                        //that.saveForm = true;
                        that.notifyMe(response.data.message, 'success')
                    }

				}).catch(( {response} ) => {
					that.notifyMe(response.data.errors, 'warn')
					that.loadingData 	 = false
				})
               
            },            
            editWebhook: function () {

                if (this.destination.name == '') {
                     return this.isname = this.$t('The_name_is_required');
                }
                if (this.destination.endpoint == '') {
                    return this.isendpoint = this.$t('Enter_a_valid_url_endpoint_for_your_destination');
                }
                if (this.destination.method == '') {
                    return this.ismethod = this.$t('Choose_a_request_method');
                }                
                if (this.idsources == '') {
                    return this.issource = this.$t('Choose_at_least_1_source');
                }
                if (this.idstatus == '') {
                    return this.isstatus = this.$t('Choose_at_least_1_status');
                }

                let that = this;
                let dataa = {
                     status_id : this.idstatus.map(function(value) {
                        return value.id;
                    }), //Statuses

                    // source_id : [this.idsources], //sources
                    source_id : this.idsources.map(function(value) {
                        return value.id;
                    }), //sources
                 };
                 that.destination.settings.status_id = dataa.status_id;
                 that.destination.settings.sources_id = dataa.source_id;
                 that.destination.active = that.active;

                let url   = '/webhook/updateSettings';
				let method= 'POST';
				let data  = that.destination;

                 that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.loadingData = false;
                    that.feedbackMessage = '';
                    if(!response.data.success) {
                        let error = response.data.errors
                        
                        if(error){
							Object.keys(error).forEach( function( item ) {
                                console.log(item)
								if(that.feedbackMessage != '') {
									that.feedbackMessage += '<br>'
								}
								that.feedbackMessage += item + "- " + error[item][0]
							} )
                            that.notifyMe(that.feedbackMessage, 'warn');
						}
					}else{
                       that.$router.push({path: '/destination'});
                        that.notifyMe(response.data.message, 'success')
                    }

				}).catch(( {response} ) => {
					that.notifyMe(response.data.errors, 'warn')
					that.loadingData 	 = false
				})
               
            },
            changename(){
                 this.isname = '';
            },
            changeendpoint(){
                 this.isendpoint = '';
            },
            changemethod(){
                 this.ismethod = '';
            },
            changemplateform(){
				this.mailshimpUrl.part1 = '';
				this.mailshimpUrl.part2 = '';
                // this.addRequestHeader();
                this.destination.request_headers = [];
                this.destination.endpoint = '';
                if (this.destination.plateform == 'sendinblue') {
                    this.destination.request_headers.push({key:'api-key',value:''});
                    this.destination.endpoint = 'https://api.sendinblue.com/v3/contacts';
                    document.getElementById('newAddhk').attr('disabled', true);
                }
                if (this.destination.plateform == 'mailchimp') {
                    this.destination.request_headers.push({key:'Authorization',value:''});
                    document.getElementById('newAddhk').attr('disabled', true);
                } 
                if (this.destination.plateform == 'webhook') {
                    this.destination.request_headers.push({key:'',value:''});
                    document.getElementById('newAddhk').attr('disabled', true);
                }
            },
            changestatus(){
                 this.isstatus = '';
            },
            changesource(){
                 this.issource = '';
                 this.getParameters();
            },
            change:function(e){
                const url = e.target.value;
                this.isURLValid(url);
            },
            isURLValid: function(inputUrl) {
                var url = new RegExp(this.regex,"i");
                this.isValid = url.test(inputUrl);
            },
            eventCallBack() {
                this.saveForm = false;
                this.destination.name  = '';
                this.destination.endpoint  = '';
                this.destination.active  = true;
                this.destination.method  = 'Post';
                this.destination.settings  = {"status_id":'',"sources_id":''};
                this.destination.request_headers  = [];
                this.destination.request_query  = [];
                this.destination.parameters  = [];
                 
                this.idstatus = [];
				this.idsources = [];
                this.getSourcesStatus();
                this.getParameters();
                this.id_edit = '';
			},
			custommailchimpUrl(){
				this.destination.endpoint = "https://"+this.mailshimpUrl.part1+".api.mailchimp.com/3.0/lists/"+this.mailshimpUrl.part2+"/members"
			}

        }
    }
</script>

<style lang="scss">
    .oml-newDesti-container{
        .fs-11{
            font-size:11px;
        }
        .fs-13{
            font-size:13px;
        }
        .fs-14{
            font-size:14px;
        }
		.fw-600{
			font-weight:600;
		}
        .color-6c6c6c{
            color:#6c6c6c;
        }
		.color-1e83ef{
			color:#1e83ef;
		}
        .oml-newDesti-content{
            padding-bottom:30px;

            .oml-newDesti-header{
                display:flex;
                align-items:center;
                justify-content:space-between;

                .oml-newDesti-infoCard{
                    background-color:#eefafe;
                    border-radius:6px;
                    padding:8px 15px;

                    p{
                        margin:0;
                        display:flex;
                        align-items:center;
                        color:#0a75a7;
                        font-size:14px;
                        font-weight:400;
                        line-height:28px;

                        i{
                            font-size:24px;
                            margin-right:10px;
                            color:#0a75a7;
                        }

                        a{
                            text-decoration:underline;
                            color:#0a75a7;
                            font-weight:600;
                            margin-left:10px;
                        }
                    }
                }
            }

            .oml-newDesti-searchFilter{
                display:flex;
                align-items:center;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                border-radius:10px;
                margin-top:25px;

                .oml-newDesti-search{
                    width:65%;

                    .input-searchZone-md{
                        margin:0;
                        width:100%;
                        display:flex;
                        align-items:center;
                        margin-left:0px !important;

                        i{
                            font-size:20px;
                        }

                        .input-searchZone{
                            width:100%;
                            border:0px solid transparent;
                            padding-left:40px;
                        }
                    }
                }

                .oml-newDesti-filter{
                    width:35%;
                    display:flex;
                    align-items:center;
                    justify-content: space-between;
                    background-color:#f3f3f3;

                    label{
                        font-size:14px;
                        padding:0 10px;
                        margin-bottom:0px;
                    }

                    .selectBox{
                        .multiselect{
                            color:#1d2841;

                            .multiselect__tags{
                                background-color:transparent;
                                border:0px solid transparent;
                                color:#1d2841;

                                .multiselect__single{
                                    background-color:transparent;
                                    border:0px solid transparent;
                                    color:#1d2841;
                                    font-size:14px;
                                    margin:0;
                                }

                                .multiselect__placeholder{
                                    color:#1d2841;
                                    font-size:14px;
                                    margin:0;
                                }
                            }

                            .multiselect__select{
                                &:before{
                                    border-width: 4px 4px 0;
                                    top:70%;
                                }
                            }

                            .multiselect__content-wrapper{
                                min-width:300px;
                                right:0;
                            }

                        }
                    }

                    .hr-y-e2e2e2{
                        width:1px;
                        height:30px;
                        background-color:#e2e2e2;
                        display:block;
                    }
                }
            }


            .oml-newDesti-form{
                width:80%;
                margin:0 auto;

                .oml-newDestiForm-title{
                    .custom-control-label{
                        font-size:14px;
                        line-height:30px;
                    }
                }

                .form-group{
                    label{
                        color:#1d2841;
                        font-weight:600;
                    }

                    .input-withAction{
                        display:flex;
                        align-items:stretch;

                        input{
                            width:100%;
							border: 1px solid #e8e8e8 !important;
							font-size:14px;

                            &.isInvalid{
                                border:1px solid red;
                            }
							
							&[disabled="disabled"]{
								background-color:#ececec !important;
							}
							
							&::placeholder{
								font-size:14px;
							}
                        }

                        select{
                            width:100%;
                            margin-right:8px;
                            height:38px;
							
                        }

                        button{
                            width:40px;
                            background-color:#fff;
                            border:1px solid #c6c6c6;
                            border-radius:5px;
                            display:flex;
                            align-items:center;
                            justify-content:center;

                            i{
                                font-size:22px;
                                color:#c6c6c6;

                            }
                        }
                    }

                    button{
                        &.newAdd{
                            width:40px;
                            background-color:#fff;
                            border:1px solid #c6c6c6;
                            border-radius:5px;
                            display:flex;
                            align-items:center;
                            justify-content:center;

                            i{
                                font-size:22px;
                                color:#c6c6c6;

                            }
                        }

                    }

                    .groupInput-withAction{
                        display:flex;
                        align-items:center;

                        .first-input{
                            width:50%;
                            margin-right:8px;
                            position:relative;

                            input{
                                padding-left:30px;

                                &[disabled="disabled"]{
                                    background-color:#ececec !important;
                                }
                            }

                            i{
                                position:absolute;
                                top:0;
                                bottom:0;
                                left:5px;
                                margin:auto;
                                font-size: 20px;
                                height: 30px;
                            }
                        }

                        button{
                            width:40px;
                            background-color:#fff;
                            border:1px solid #c6c6c6;
                            border-radius:5px;
                            display:flex;
                            align-items:center;
                            justify-content:center;

                            i{
                                font-size:22px;
                                color:#c6c6c6;

                            }
                        }

                        .second-input{
                            width:50%;
                            margin-right:8px;
                            margin-left:8px;
                        }


                    }

                    .form-group{
                        margin-left:40px;
                        border-left:8px solid #e4e4e4;
                        padding-left:10px !important;
                    }
                }

                .oml-newDesti-actions{
                    display:flex;
                    align-items:center;
                    justify-content: flex-end;
                    margin-top:30px;

                    button{
                        min-width:150px;
                        border-radius:5px;
                        margin:0 8px;
                        padding:6px 10px;

                        &.clear-btn{
                            border:1px solid #8d8d8d;
                            background-color:#fff;
                            color:#8d8d8d;
                        }

                        &.save-btn{
                            border:1px solid transparent;
                            background-color:#06aff3;
                            color:#fff;
                        }
                    }
                }
            }

            .oml-newDesti-savedData{
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:center;
                padding:10% 0;

                & > i{
                    font-size:65px;
                    color:#4caf50;
                }

                h3{
                    font-size:18px;
                    color:#1d2841;
                    font-weight:700;
                }

                p{
                    font-size:14px;
                    font-weight:400;
                    color:#1d2841;
                }

                button{
                    border:1px solid transparent;
                    background-color:#06aff3;
                    color:#fff;
                    padding:6px 20px;
                    border-radius:6px;
                    display:flex;
                    align-items:center;

                    i{
                        margin-right:8px;
                    }
                }
            }
        }
    }
</style>
